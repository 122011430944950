.toggle-items
  display: flex
  justify-content: stretch
  list-style: none
  padding: 4px
  margin: 0
  background: rgba(0, 0, 0, 0.05)
  box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.05), inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)
  border-radius: 8px
  font-weight: 500
  &_big
    font-size: 16px
  &_big &__item
    padding: 4px 20px
  &__item
    cursor: pointer
    color: rgba(0,0,0,0.6)
    border-radius: 4px
    padding: 2px 16px
    flex-grow: 1
    text-align: center
    &_active
      color: black
      background: white
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.02), 0px 5px 10px rgba(0, 0, 0, 0.15)
