.form-validation
  margin-top 32px

  &__group
    padding 7px 14px
    border-radius 5px
    background-color rgba(#c00, 0.04)

    & + &
      margin-top 5px

  &__field
    font-size 14px
    font-weight bold

  &__messages
    margin-top 3px

  &__message
    font-size 12px
    color rgba(#000, 0.7)
