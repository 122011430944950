.hamburger
  width: 18px;
  height: 14px;
  cursor: pointer;
  position: relative;
  display: inline-block;

  span
    height 2px
    width 100%
    display block
    position absolute
    background-color #000

    &:nth-child(1)
      top 0

    &:nth-child(2)
      top 50%
      transform translate3d(0, -50%, 0)

    &:nth-child(3)
      bottom 0

  &_animated span
    transition all var(--menu-animation-duration) ease

  &:hover span, &_opened span
    background-color #0077FF

  &_opened span
    &:nth-child(1)
      top 6px
      transform rotate(135deg)

    &:nth-child(2)
      opacity 0
      transform translate3d(-100%, -50%, 0)

    &:nth-child(3)
      bottom 6px
      transform rotate(-135deg)
