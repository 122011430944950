.counter
  width 114px
  height 40px
  display flex
  min-width 114px
  border-radius 8px
  background #FAFAFA
  box-sizing border-box
  box-shadow 0 0 0 1px $black_15 inset
  transition box-shadow 80ms ease

  &_disabled
    opacity 0.6
    background-color #efefef

  &__btn
    min-width 32px
    min-height 32px
    margin 4px
    border-radius 5px
    border-radius 4px
    background #FFFFFF
    display flex
    color $accent_color
    border none
    outline none
    align-items center
    justify-content center
    transition all 80ms ease
    box-shadow 0px 1px 0px $black_10, 0px 0px 0px 1px $black_2, 0px 5px 10px $black_15

    &_disabled
      box-shadow none
      background none
      pointer-events none
      color rgba(#000, 0.2)

    &:active
      background #fcfcfc
      box-shadow 0px 1px 0px $black_10, 0px 0px 0px 1px $black_2, 0px 2px 2px $black_15

    &:active, &:hover
      color $accent_color

  &__input
    flex 1
    width 100%
    border none
    padding 0
    background none
    text-align center
    outline none
    font-size 16px
    line-height 22px

