.people
  // padding 0 40px 40px

  &__controls
    padding 16px 0

  &__content
    display grid
    grid-template-columns 1fr 1fr
    grid-template-rows min-content
    align-items flex-start
    grid-gap 20px
