.webhook-wrap
    flex 1
    width 100%
    height 100%
    display flex
    justify-content space-between
    flex-direction column

    &__content
        flex 1

    &__footer
        box-sizing: content-box !important
        flex none
        width 100%
        height 112px - 24px
        background rgba(0, 0, 0, 0.03)
        border 1px rgba(0, 0, 0, 0.05) solid
        margin-bottom: -40px
        margin-left: -42px
        padding-left: 40px
        padding-right: 40px
        
        display: flex
        align-items: top
        padding-top: 24px

    &__footer-text
        width: 680px
        color: rgba(0, 0, 0, 0.6)
        & p
            margin: 0
            margin-bottom: 6px
        & a
            color: #0099FF

    &__footer-icon
        margin: 0px 24px

.webhook
    &__title
        font-weight 500
        margin-bottom 24px

    &__title-base
        color rgba(0, 0, 0, 0.6)
        cursor pointer

    &__controls
        display flex
        justify-content space-between
        margin-bottom 32px

    &__content
        display grid
        grid-template-columns 1fr 1fr
        grid-template-rows min-content
        align-items flex-start
        grid-gap 20px

.webhook-delete-modal
    &__width-button
        width 170px

    &__modal-text
        margin-bottom 32px

.webhook-list
    &__item
        display grid
        grid-template-columns 58px 394px 240px 196px
        width 58px + 394px + 240px + 196px + 12px
        margin-left -12px
        padding-left 12px
        align-items center
        height 48px
        font-size 16px

        &:hover
            background-color #FAFAFA

        &:hover ^[0]__item-control
            display flex !important
            justify-content space-between

    &__item-url
        color black
        max-width 370px
        overflow hidden
        text-overflow ellipsis
        cursor pointer

    &__item-date
        color rgba(0, 0, 0, 0.4)

    &__item-control
        display none

.webhook-form
    &__form-row
        paddingLeft 16

    &__form-toggle
        justify-content flex-end

.webhook-detail
    &__width-button
        width 170px

    &__black-text
        color black

    &__activator
        width 135px

    &__url-space
        grid-template-columns auto 135px

    &__url-input
        align-self stretch
        width auto

    &__form-label
        padding-left 0px

    &__form-row
        margin-bottom 24px

    &__delete-button
        width 170px
        margin-right auto

    &__cancel-button
        margin-right 16px
        width 170px

    &__status
        margin-right 16px

    &__save-button
        width 170px

    &__headers
        background #FAFAFA
        border-radius 3px

    &__headers-control
        height 40px

    &__headers-add
        & span
            color #0099FF !important

        height 32px
        width 32px
        padding 0px !important
        margin-right 12px

    &__headers-row
        grid-template-columns 1fr 1fr 28px
        margin 8px 16px 8px 16px

        &:last-child
            margin-bottom 24px

    &__headers-remove
        height 32px
        width 32px
        padding 0px 0px !important

    &__headers-input
        height 32px
        min-height 32px
        background white

    &__controls
        display flex
        justify-content flex-end
        align-items center
        margin-bottom: 32px

.webhook-payload
    background #FAFAFA
    border-radius 3px

    &__title
        padding 8px 0px

        &_black
            color black

    &__content
        padding 8px 16px

    &__content-row
        margin-bottom 16px
