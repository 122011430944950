.projects-page
  min-height calc(100vh - var(--header-height))
  background-color #FAFAFA

  &__loading
    width 100%
    height 100%
    display flex
    align-items center
    justify-content center
    min-height calc(100vh - var(--header-height))

  &__list
    padding 40px
    display grid
    grid-autoflow columns
    box-sizing border-box
    grid-gap 32px
    grid-auto-rows 1fr
    grid-template-columns repeat(4, 1fr)

  &__link
    color #000
    display block
    display flex
    text-decoration none

    &:hover
      color #000

  &__pages
    bottom 0
    width 100%
    position sticky
    display flex
    padding 10px 40px
    justify-content flex-end
    box-sizing border-box
    background-color #fafafa

  @media (min-width 1200px)
    &__list
      grid-template-columns repeat(3, 1fr)

  @media (min-width 1360px)
    &__list
      grid-template-columns repeat(4, 1fr)

  @media (min-width 1460px)
    &__list
      grid-template-columns repeat(5, 1fr)

.empty-projects-page
  color $grey_8
  text-align center
  display flex
  flex-direction column
  align-items center
  &__heidi
    height 256px
    margin-top 24px
  &__header
    font-size 32px
    font-weight 700
    margin 16px
    color $grey_8
  p
    font-size 24px
  &__action
    margin 72px 0 32px

.project-card
  --text-color #000
  flex 1
  display flex
  flex-direction column
  background #FFFFFF
  box-shadow 0px 1px 2px rgba(0, 0, 0, 0.15)
  border-radius 4px
  overflow hidden
  color var(--text-color)

  &__header
    padding 12px 16px
    box-shadow 0 1px 0 rgba(0, 0, 0, 0.1)

  &__menu
    margin-left auto

    .button
      opacity 0.5
      margin-right -10px
      --button-color var(--text-color)

      &__icon
        width 18px
        height 18px

  &__title
    display flex
    font-size 16px
    font-weight 500
    margin-bottom 5px
    line-height 22px
    align-items center

    &-text
      max-width 250px
      white-space nowrap
      overflow hidden
      text-overflow ellipsis

  &__summary
    flex 1
    font-size 14px
    line-height 22px

  &__total
    opacity 0.6

  &__annotation
    display flex
    justify-content space-between

  &__detail
    display grid
    grid-auto-flow column
    grid-gap 16px
    grid-auto-columns max-content

    &-item
      --icon-color #fff
      display flex
      align-items center

      ^[0]__icon
        color var(--icon-color)

      &_type
        &_completed
          --icon-color #64BF00
        &_rejected
          --icon-color #DD0000
        &_predictions
          --icon-color #0077FF

  &__icon
    margin-right 10px

  &__description
    flex 1
    opacity 0.6
    font-size 16px
    line-height 22px
    padding 12px 16px 0
    overflow hidden
    color rgba(#000, 0.6)
    text-overflow ellipsis
    display -webkit-box
    word-break break-word
    -webkit-line-clamp 3
    -webkit-box-orient vertical

  &__info
    display flex
    align-items center
    padding 16px 16px 10px 16px
    justify-content space-between

  &__created-date
    color rgba(#000, 0.4)
    font-size: 90%

    & > span
      font-weight 500

  &_colored
    --text-color #fff
    background-color var(--background-color)

  &_colored ^[0]
    &__header
      background-color var(--header-color)
      box-shadow none

    &__detail
      &-item
        --icon-color #fff


