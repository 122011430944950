.input,
.select,
.textarea
  height 40px
  min-height 40px
  background #FAFAFA
  font-size 16px
  line-height 22px
  border 1px solid $black_15
  box-sizing border-box
  border-radius 5px
  padding 0 16px
  transition box-shadow 80ms ease

  &_ghost
    border none
    padding 0
    background-color transparent
    outline none

input.input[type=radio]
  width: 16px
  height: 16px
  min-height: 0

.textarea
  padding 12px 16px
  min-height 50px
