.create-project
  flex: 1
  min-height: 0
  display: flex
  flex-direction: column
  > .modal__header
    border-bottom: 1px solid $black_10
    h1
      width: 224px // to be the same size as two buttons to align .toggle perfectly to the center
      margin-right: auto
      margin-bottom: 0
      margin-top 0
      font-size: 20px
    button
      line-height: 1em
      min-width: 100px
    .toggle-items
      width: 500px
      margin-right: auto
      box-sizing: border-box
  &__tab_disabled
    display: flex
    align-items: center
    justify-content: center
    &::before
      content: ""
      display: block
      height: 8px
      width: 8px
      border-radius: 4px
      margin-right: 6px
      background: $red_7
  form.project-name
    width: 500px
    margin: 32px auto
  form.project-name > *:not(:first-child)
    margin-top: 32px
