.modelVersionSelector
  width 100%
  display flex
  flex-direction column
  gap 12px

  &__message
    font-size 16px
    color #DD0000
    margin-bottom 12px

