.dl
  margin 0
  display grid
  font-size 16px
  line-height 22px
  color rgba(#000, 0.6)
  grid-template-columns 30% 70%
  grid-row-gap 12px

  &__dt
    font-weight 500

  &__dd
    margin 0
