.sidebar-menu
  flex 1
  display flex
  max-height calc(100vh - var(--header-height))

  &__navigation
    width 241px // to match the 1px border of the logo in header
    display flex

  &__content
    flex 1
    overflow auto
    padding 32px 40px
    box-shadow -1px 0 0 0 rgba(#000, 0.1)
