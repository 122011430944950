.ml
  display grid
  grid-template-columns repeat(3, 1fr)
  grid-column-gap 16px
  grid-row-gap 16px
  margin-top 32px
  align-items flex-start
  grid-auto-rows: max-content;

  &__info
    display flex
    align-items center
    justify-content flex-end

  &__status
    color rgba(#000, 0.4)
    display flex
    align-items center
    margin-right 10px

  &__summary
    margin-bottom 16px

  &__indicator
    width 8px
    height 8px
    display block
    margin-right 8px
    border-radius 100%
    background-color var(--indicator-color)

    &_state_CO
      --indicator-color #7fc84d

    &_state_DI
      --indicator-color #FFA940

    &_state_ER
      --indicator-color #FF4D4F

    &_state_TR,
    &_state_PR
      --indicator-color #4095ff

      position relative

      &::before
        top 0
        left 0
        content ''
        width 100%
        height 100%
        opacity 0.5
        display block
        border-radius 100%
        background-color var(--indicator-color)
        animation state-pulse 2s ease infinite

@keyframes state-pulse
  0%
    transform scale(1)

  50%
    opacity 0
    transform scale(3)

  100%
    opacity 0
    transform scale(3)
