body
  --header-height 48px
  --menu-animation-duration 0.15s
  --menu-animation-curve cubic-bezier(0.21, 1.04, 0.68, 1);
  --menu-animation-start -10px
  --menu-sidebar-width 240px
  margin 0

.app-wrapper
  width 100vw
  max-width 100%
  min-height 100vh
  .loading
    position: relative;
    width: 30px;
    height: 30px;
    border: 2px solid #000;
    border-top-color: rgba(0, 0, 0, 0.2);
    border-right-color: rgba(0, 0, 0, 0.2);
    border-bottom-color: rgba(0, 0, 0, 0.2);
    border-radius: 100%;
    animation: circle infinite 0.75s linear;

.global-error
  padding 32px

  &__heidi
    display block
    margin 32px auto

  h1
    text-transform uppercase
    text-align center
    font-size 20px

  h2
    font-size 20px
    color var(--red-6)

  &__details
    background #f5f5f5
    max-height 320px
    overflow-y auto
    white-space pre-wrap
    margin 16px 0
    padding 16px

  &__actions
    display flex
    gap 8px
    > *
      line-height 1em

  &__slack
    margin-right auto
    display flex
    align-items center

    img
      height 16px
      width 16px
      margin-right 8px

.color
  margin 4px 8px
  position relative

  &::before
    width 24px
    height 24px
    display block
    margin 0 auto
    content ''
    border-radius 100%
    background-color var(--background)
    box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.15);
