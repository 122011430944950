.app-wrapper{
  width: 100vw;
  max-width: 100%;
  min-height: 100vh;
}
.loading {
  position: relative;
  left: 50%;
  top: 149px;
  width: 60px;
  height: 60px;
  border: 4px solid #000;
  border-top-color: rgba(0, 0, 0, 0.2);
  border-right-color: rgba(0, 0, 0, 0.2);
  border-bottom-color: rgba(0, 0, 0, 0.2);
  border-radius: 100%;

  animation: circle infinite 0.75s linear;
}

@keyframes circle {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}