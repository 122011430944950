.breadcrumbs
  height 100%
  display flex
  align-items center

  &__list
    height 100%
    display flex
    align-items center
    list-style-type none
    margin 0
    padding 0

  &__item
    font-family Roboto
    font-size 16px
    line-height 22px
    position relative
    margin 0
    padding 0
    height 100%
    display flex
    align-items center
    cursor default

    &:not(&_last)
      cursor pointer

      & > span,
      & > a
        opacity 0.6

  &__item > span,
  &__item > a
    color #000
    text-decoration none

  &__item + &__item
    margin-left 30px

  &__item + &__item::before
    top 50%
    right 100%
    width 30px
    content "/"
    height 16px
    display block
    opacity 0.5
    position absolute
    transform translate3d(0, -50%, 0)
    text-align center
    line-height 16px
    font-size 18px

  &__settings
    width 20px
    height 20px
    display block
    margin-left 10px

  &__settings img
    display block
    opacity 0.23
