.form
  width 100%
  display block

  &__row
    display grid
    justify-items stretch
    justify-content space-between
    grid-template-columns repeat(var(--column-count, 5), 1fr)
    grid-column-gap 16px
    grid-row-gap var(--row-gap, 16px)

    &:not(:first-child)
      margin-top 12px

  &__submit
    display flex
    margin-top 32px
    align-items center
    justify-content space-between

    & + .inline-error
      margin-top 32px

    &_size
      &_small
        margin-top 16px

  &__info
    display flex
    align-items center
    color #d00
    font-size 14px
    line-height 22px

    &_valid
      color rgba(#000, 0.4)

  &__column
    display grid
    grid-auto-flow column
    align-items flex-start

.input,
.textarea,
.counter,
.select__list
  &:not(&_ghost):focus,
  &_focused
    outline none
    box-shadow 0px 0px 0px 6px rgba($accent_color, 0.2), inset 0px -1px 0px rgba(#000, 0.1), inset 0px 0px 0px 1px rgba(#000, 0.15), inset 0px 0px 0px 1px rgba($accent_color, 0.2)
    border-color rgba($accent_color, 0.2)

.form-indicator
  font-weight 500

  &__item
    &_type
      &_success
        color #59aa05
      &_fail
        color $danger_color
