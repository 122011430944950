.content-wrapper
  display flex
  min-height 100vh
  flex-direction column

  &__body
    flex 1
    display flex
    min-width 100%
    min-height 100%
    flex-direction column
    max-height calc(100vh - var(--header-height))

  &__content
    flex 1
    display flex
    flex-direction column
    margin-left 0
    height 100%
    background-color #fff
    transition margin var(--menu-animation-duration) var(--menu-animation-curve)
    will-change margin-left

    &_withSidebar
      margin-left var(--menu-sidebar-width)
