.columns
  --column-default-width calc(100% / var(--columns))
  --column-size var(--column-width, var(--column-default-width))
  display grid
  grid-template-columns repeat(var(--columns), var(--column-size))
  grid-column-gap var(--column-gap, 10px)

  &__title
    margin-bottom 32px
    font-weight 500
    font-size 16px
    line-height 22px
    padding 0 16px
