.userpic
  width 28px
  height 28px
  display flex
  font-size 12px
  overflow hidden
  position relative
  align-items center
  border-radius 100%
  color rgba(0,0,0,0.8)
  justify-content center
  background rgba(0, 0, 0, 0.04)
  box-shadow inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1)

  img
    opacity 0
    width 100%
    height 100%
    font-size 12px
    line-height 22px
    object-fit cover
    position absolute

  &__username
    display block
    line-height 22px
    font-weight bold
    font-family Roboto
    opacity 0.4
