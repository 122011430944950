.card
  border-radius 5px
  background-color rgba(#000, 0.03)

  &__header
    display flex
    height 48px
    padding 0 15px
    align-items center
    font-weight 500
    font-size 16px
    line-height 22px
    justify-content space-between
    box-shadow 0 1px 0 0 rgba(#000, 0.15)

    &-content
      display flex
      align-items center

  &__content
    padding 15px

  &:not(:first-child)
    margin-top 24px
